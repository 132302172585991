import React, { Component } from 'react';

import * as moment from 'moment';

class VideoInfo extends Component {

    getPostedDateString() {
        // Format the postedAt date for view. worst case, just make something up
        var { video } = this.props
        var posted = moment('December 10 2021, 7:24:55 pm', 'MMMM Do YYYY, h:mm:ss a') // random date lol
        if (video?.dateTime?.seconds) {
            posted = moment.utc(video?.dateTime?.seconds*1000)
        }
        return posted.format("ddd MMM DD @ h:mm A")
    }

    render() {
        var { video } = this.props

        return <div className="VideoInfo"> 
            {!!video.userData ? 
                <div>
                    {!!video?.userData?.image ? <div className="ImageWrapper"> 
                        <img src={video?.userData.image} /> 
                        <a>{video?.userData.firstName} {video?.userData.lastName}</a>
                    </div> : null}
                </div> : 
                null
            }
            <div className="PlaceName">{video.place?.name}</div>
            <a>{this.getPostedDateString()}</a>
        </div>
    }
}

export default VideoInfo;