import React, { Component } from 'react';
import C from "../config/Constants.js";

class CategoryButtons extends Component {
    render () {
        var { video, onClickAmbi, onClickFD } = this.props

        var ambiStyle = {opacity: `${video?.phase === C.AMBIANCE_PHASE ? 1 : 0.2}`}
        var fdStyle = {opacity: `${video?.phase === C.FOOD_DRINK_PHASE ? 1 : 0.2}`}
        return <div className="CategoryButtons">
            <button style={ambiStyle} onClick={onClickAmbi}>Ambiance</button>
            <button style={fdStyle} onClick={onClickFD}>Food & Drinks</button>
        </div>
    }
}

export default CategoryButtons;