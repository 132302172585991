import React, { Component } from 'react';

class StarRating extends Component {

    render() {
        var rating = this.props.rating
        var stars = []
        // Fill in each star with a linear color gradient corresponding to the rating and the star's position out of 5
        for (let starNum = 0; starNum < 5; starNum++) {
            var fillPercent = Math.floor(100*Math.min(rating - starNum, 1))
            var gradName = `grad${starNum})`
            stars.push(
                <svg key={starNum} fill={`url('#${gradName}')`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                    <linearGradient id={gradName} x1="0" x2="100%" y1="0" y2="0">
                        <stop offset={`${fillPercent}%`} stopColor="black"/>
                        <stop offset={`${fillPercent}%`} stopColor="white"/>
                    </linearGradient>
                    </defs>
                    <path d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                </svg>
            )
        }
        return <div className="StarRating">
            {stars}
            <div>{rating}</div>
        </div>
    }
}

export default StarRating;