import React, { Component } from 'react';
import C from "../config/Constants.js";

// class PlaceInfoButton extends Component {
//     render () {
//         return <button>x Place Info</button>
//     }
// }
//style="width: 100%; height: auto; float: left;background-image: none;"

var toggle1 = () => {
    // action('pause');
    window.open('https://mywebsite.url', '_blank');
}

const PlaceInfoButton = ({ toggleMore, action }) => {
    return <div className="PlaceInfoButtonContainer">
        <div className="PlaceInfoButton" onClick={toggleMore}>
            <svg style={{width:"18px", height:"15px",  padding: "0 5px 0 0"}} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0761 6.7878C12.0761 11.0302 6.62154 14.6666 6.62154 14.6666C6.62154 14.6666 1.16699 11.0302 1.16699 6.7878C1.16699 5.34116 1.74167 3.95378 2.76459 2.93085C3.78752 1.90793 5.1749 1.33325 6.62154 1.33325C8.06817 1.33325 9.45556 1.90793 10.4785 2.93085C11.5014 3.95378 12.0761 5.34116 12.0761 6.7878Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.62154 8.60598C7.62569 8.60598 8.43972 7.79195 8.43972 6.7878C8.43972 5.78364 7.62569 4.96962 6.62154 4.96962C5.61738 4.96962 4.80336 5.78364 4.80336 6.7878C4.80336 7.79195 5.61738 8.60598 6.62154 8.60598Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Place Info
        </div>
    </div> 
}

export default PlaceInfoButton;