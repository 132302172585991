import React, { Component } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";

class Map extends React.Component {
    constructor(props) {
      super(props);

      this.initMap = this.initMap.bind(this);
      setTimeout(this.initMap, 100)
    }

    // Initialize and add the map
    initMap() {
        const { lat, lng, name } = this.props
        // The location of place
        const coords = { lat: lat, lng: lng };

        // The map, centered at coords
        const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 15,
            center: coords,
        });

        // The marker, positioned at coords
        const marker = new window.google.maps.Marker({
            position: coords,
            map: map,
            title: name,
        });
    }

    render() {
        return <Wrapper apiKey={"AIzaSyD3eehAy_T_UXqAImhAhHazOioXYkAC2vs"} >
            <div id="map"></div>
            <script
                id="google-map-script"
                src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD3eehAy_T_UXqAImhAhHazOioXYkAC2vs&callback=initMap&libraries=&v=weekly`}
                async defer
            />
        </Wrapper>
    }
}

export default Map;