import logo from './logo.svg';
import './App.css';

import { initializeFirebase } from "./data/firebase.js";
import VideoComponent from "./components/VideoComponent.js";
import React from 'react';


class App extends React.Component {
  constructor(props) {
    super(props);

    // parse keys we need from the url. Save them in state so we can use them to look stuff up in firebase
    const urlParse = new URL(window.location)
    const placeName = urlParse.pathname.substring(1) // includes a leading /
    const placeId = urlParse.searchParams.get("place_id")
    const videoId = urlParse.searchParams.get("video_id")

    this.state = {
      selected_place_name: placeName,
      selected_place_id: placeId,
      selected_video_id: videoId,
      
      place: {},
      videos: [],
    };
  }

  componentDidMount() {
    var place_name = this.state.selected_place_name
    var place_id = this.state.selected_place_id
    var video_id = this.state.selected_video_id

    initializeFirebase(place_name, video_id).then((resp) => {
      this.setState({ place: resp['place'], videos: resp['videos'] })
    })
  }

  render () {
    var { videos } = this.state

    return (
      <div className="App" title="Ombi">
        <header className="App-header"></header>
        <VideoComponent videos={ videos } />
      </div>
    );
  }
}

export default App;
