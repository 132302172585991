import React, { Component } from 'react';
import _ from 'lodash';

import StarRating from './StarRating';
import ReservationButtons from './ReservationButtons';
import Map from "./Map.js";

class PlaceInfo extends Component {

    render() {
        const { data, close } = this.props
        const name =  _.get(data, "place.name", "")
        const neighborhood =  _.get(data, "place.neighborhood", "")
        const lat =  _.get(data, "place.location._lat", "")
        const long =  _.get(data, "place.location._long", "")

        const experienceItems =  _.get(data, "place.experience_type", []).map((item) => <button key={item}>{item}</button>)
        const atmosItems =  _.get(data, "place.vibes", []).map((item) => <button key={item}>{item}</button>)
        const hours =  _.get(data, "place.hours", []).map((dayhours) => {
            const dumblist = dayhours.split(": ")
            const day = dumblist[0]
            const hours = dumblist[1]
            return (<div className="DayHours" key={dayhours}>
                <h4>{day}</h4> <a> {hours} </a>
            </div>
        )})

        return <div className="PlaceInfoContainer">
            <Map name={name} lat={lat} lng={long} />
            <div className="PlaceInfo">
                <button className="closeButton" onClick={close}>x</button>
                <div>
                    <a>{neighborhood}'s</a>
                    <h1>{name}</h1>
                </div>
                <div>
                    <a>EXPERIENCE TYPES</a>
                    <br/>
                    <div>{experienceItems}</div>
                </div>
                <br />
                <div>
                    <a>ATMOSPHERE</a>
                    <br />
                    <div>
                        {atmosItems}
                    </div>
                </div>
                <br />
                <div>
                    <a>RATINGS</a>
                    <br />
                    <div><StarRating rating={data?.place?.rating.toFixed(1) || 4.5}/></div>
                </div>
                <div>
                    <a> {hours} </a>
                </div>
                <ReservationButtons resLink={data?.place?.reservation_url} directionLink={data?.place?.googleUrl} />
            </div>
        </div>
    }
}

export default PlaceInfo;