// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore, collection, query, where, getDocs, getDoc, doc, orderBy, limit } from "firebase/firestore";
import C from "../config/Constants.js";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = require('../config/firebase_config.json');

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export async function initializeFirebase(place_name, video_id) {
    // Load all the metadata and top videos and play them in order
    // If we get asked for a specific video, play that first
    // Then for the place:
    // 1. show top 3 ranked videos for ambiance
    // 2. show top 3 ranked vidoes for F+D
    // 3. deeplink to ios
  var videoRefs = []
  var place = {}
  var name = place_name.replace("_", " ") // Since we can't have spaces in URLs, lets just say they give us underscore for now

  // Get the place data and top videos for the place
  if (name) {
    var normName = ''
    // TODO: this is just trying to find the place name when the user input isn't quite right. Change to wildcard or fuzzy match.
    for (var i=0; i<name.length; i++) {
      var char = name[i]
      if (i==0 || (i > 0 && name[i-1] === ' ')) {
        char = char.toUpperCase()
      }
      normName += char
    }

    const placeRef = query(collection(db, "places"), where("name", "==", normName))
    const placeSnap = await getDocs(placeRef);
    placeSnap.forEach((placeR) => {
      place = placeR.data();
      place.id = placeR.id;
    });

    try {
      // Ambiance videos
      var aVids = query(collection(db, "places", place.id, "videos"), where("phase", "==", C.AMBIANCE_PHASE), orderBy("score", "desc"), limit(C.NUM_VIDS_PER_PHASE))
      // F&D videos
      var fVids = query(collection(db, "places", place.id, "videos"), where("phase", "==", C.FOOD_DRINK_PHASE), orderBy("score", "desc"), limit(C.NUM_VIDS_PER_PHASE))

      // Request them and push them in order into the video refs array
      var [ fVidsData, aVidsData ] = await Promise.all([getDocs(fVids), getDocs(aVids)]);
      aVidsData.forEach((vidR) => {
        videoRefs.push(vidR)
      });
      fVidsData.forEach((vidR) => {
        videoRefs.push(vidR)
      });

    } catch {
      console.log("failed to load place videos")
    }
  }

  // Prepend the selected video to the place stuff
  if (video_id) {
    var vidQ = doc(db, "places", place.id, "videos", video_id)
    var vidSnap = await getDoc(vidQ);
    if (vidSnap.exists()) {
      videoRefs = videoRefs.slice()
      videoRefs.unshift(vidSnap)
    } else {
      console.log('video not found for id', video_id)
    }
  }

  // Unpack metadata and final processing of videos
  var videos = []
  for (var i = 0; i < videoRefs.length; i++) {
    var vidR = videoRefs[i]
    var vidData = vidR.data()
    vidData.place = place
    
    // Fetch user data if the ref is there
    var byUserRef = vidData.byUser
    if (!!byUserRef) {
      var info = await getDoc(byUserRef)
      // we should really not do this but it works for now...
      var fieldsMap = info._document.data.value.mapValue.fields
      var userData = {
        'image': fieldsMap['profileImage']?.stringValue,
        'firstName': fieldsMap['firstName']?.stringValue,
        'lastName': fieldsMap['lastName']?.stringValue
      }
      vidData['userData'] = userData
    }
    videos.push(vidData)
  }

  return {
    place,
    videos,
  }
}