import React, { Component, Button } from 'react';

const LINK_TO = "https://apps.apple.com/us/app/ombi-preview-restaurants/id1598753264"

class AppLink extends Component {
    constructor(props) {
        super(props);
        
        this.onClick.bind(this)
    }

    onClick() {
        window.location.replace(LINK_TO);
    };

    render() {
        return <div className="AppLink">
            <div className="modal">
                <a href={LINK_TO}>Join Ombi on iOS to see more restaurants</a>
                <br/> <br/>
                <button onClick={ this.onClick }>JOIN OMBI</button>
            </div>
        </div>
    }
}

export default AppLink;