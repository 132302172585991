import React, { Component } from 'react';

class ReservationButtons extends Component {
    constructor(props) {
        super(props);
        
        this.onDirectionClick = this.onDirectionClick.bind(this)
        this.onResClick = this.onResClick.bind(this)
    }

    onDirectionClick() {
        window.location.href = this.props.directionLink
    }

    onResClick() {
        window.location.href = this.props.resLink
    }

    render () {
        
        return <div className="ReservationButtons">
            <button className="DirectionButton" onClick={this.onDirectionClick}>Directions</button>
            <button className="MakeReservationButton" onClick={this.onResClick}>Make Reservation</button>
        </div>
    }
}

export default ReservationButtons;