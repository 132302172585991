import React, { Component } from 'react';

import AppLink from "./AppLink.js";
import VideoInfo from "./VideoInfo.js";
import PlaceInfo from "./PlaceInfo.js";
import Stories from 'react-insta-stories';
import CategoryButtons from './CategoryButtons.js';
import PlaceInfoButton from './PlaceInfoButton.js';
import C from "../config/Constants.js";

class VideoComponent extends Component {
    constructor(props) {
        super(props);

        this.onStoryStart = this.onStoryStart.bind(this)
        this.onAllStoriesEnd = this.onAllStoriesEnd.bind(this)
        this.onClickAmbiFunc = this.onClickAmbiFunc.bind(this)
        this.onClickFDFunc = this.onClickFDFunc.bind(this)

        this.state = {
            videos: this.props.videos,
            showLink: false,
            index: 0,
        };
    }
  
    onClickAmbiFunc() {
        this.setState({index: 0})
    }

    onClickFDFunc() {
        this.setState({index: this.props.videos.length - C.NUM_VIDS_PER_PHASE})
    }

    onStoryStart(e) {
        // Tell us what story we're on
        this.setState({index: e})
    }

    onAllStoriesEnd(idx, st) {
        // Bug with progressive loader causes this to be called after first video. Make sure we've really played through before showing link
        if (idx+1 >= this.props?.videos?.length) {
            this.setState({ showLink: true })
        }
    }

    render() {
        var { videos } = this.props
        var { index, showLink } = this.state
        var stories = videos.map(v => {
            return {
                type: 'video',
                url: v['url'],
                seeMore: ({ close }) => {
                    return <PlaceInfo close={close} data={v}></PlaceInfo>
                },
                seeMoreCollapsed: PlaceInfoButton
            }
        });

        // Always show app link if we're done or no videos 
        if (showLink || stories.length <= index || stories.length === 0  || videos[0]["place"] == undefined ) {
            return <AppLink />
        }
        
        return (
            <div>
                <CategoryButtons video={videos[index]}  onClickAmbi={this.onClickAmbiFunc} onClickFD={this.onClickFDFunc} />
                <VideoInfo video={videos[index]}/>
                <Stories
                    stories={stories}
                    width="100vw"
                    height="100vh"
                    onAllStoriesEnd={this.onAllStoriesEnd}
                    onStoryStart={this.onStoryStart}
                    // Uncomment to switch from insta-style controls to button controls
                    // preventDefault={true}
                    // currentIndex={index}
                />
            </div>

        );
    }
  }

export default VideoComponent;